@font-face {
  font-family: InterVariable;
  src: url("InterVariable.e6ec8b96.woff2") format("woff2"), url("InterVariable.a7cefc07.ttf") format("truetype");
  font-weight: 450 650;
  font-style: normal;
  font-display: swap;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #f4f4f6;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: InterVariable, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

[type="button"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

[type="reset"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

[type="submit"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::-webkit-input-placeholder {
  opacity: 1;
  color: #d3d4dc;
}

input::-ms-input-placeholder {
  opacity: 1;
  color: #d3d4dc;
}

input::placeholder {
  opacity: 1;
  color: #d3d4dc;
}

textarea::-webkit-input-placeholder {
  opacity: 1;
  color: #d3d4dc;
}

textarea::-ms-input-placeholder {
  opacity: 1;
  color: #d3d4dc;
}

textarea::placeholder {
  opacity: 1;
  color: #d3d4dc;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

body {
  color: #2f313f;
  font-feature-settings: "cv03", "cv04";
}

input, select {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: #e9eaee;
  border-color: rgba(233, 234, 238, var(--tw-border-opacity));
  border-radius: .25rem;
}

:root {
  --tw-border-opacity: 1;
  --tw-text-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-placeholder-opacity: 1;
}

.react-datepicker-wrapper, .react-datepicker__input-container {
  width: 100%;
  display: block;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

a {
  --tw-text-opacity: 1;
  color: rgba(29, 150, 92, var(--tw-text-opacity));
  text-decoration-line: underline;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.top-2 {
  top: .5rem;
}

.right-2 {
  right: .5rem;
}

.top-1\/2 {
  top: 50%;
}

.left-1\/2 {
  left: 50%;
}

.top-4 {
  top: 1rem;
}

.right-4 {
  right: 1rem;
}

.right-\[20px\] {
  right: 20px;
}

.top-\[-4px\] {
  top: -4px;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-auto {
  right: auto;
}

.bottom-auto {
  bottom: auto;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.z-30 {
  z-index: 30;
}

.z-20 {
  z-index: 20;
}

.float-left {
  float: left;
}

.m-0 {
  margin: 0;
}

.m-auto {
  margin: auto;
}

.m-1 {
  margin: .25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-\[-12\] {
  margin-top: -12px;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-8 {
  margin-top: 2rem;
}

.ml-auto {
  margin-left: auto;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.-mb-4 {
  margin-bottom: -1rem;
}

.mt-3 {
  margin-top: .75rem;
}

.-mt-10 {
  margin-top: -2.5rem;
}

.-mt-3 {
  margin-top: -.75rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-0 {
  margin-top: 0;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.h-6 {
  height: 1.5rem;
}

.h-14 {
  height: 3.5rem;
}

.h-24 {
  height: 6rem;
}

.h-1 {
  height: .25rem;
}

.h-4 {
  height: 1rem;
}

.h-8 {
  height: 2rem;
}

.h-12 {
  height: 3rem;
}

.h-full {
  height: 100%;
}

.h-\[80px\] {
  height: 80px;
}

.h-px {
  height: 1px;
}

.h-\[26px\] {
  height: 26px;
}

.h-\[380px\] {
  height: 380px;
}

.h-\[425px\] {
  height: 425px;
}

.h-max {
  height: -webkit-max-content;
  height: max-content;
}

.h-20 {
  height: 5rem;
}

.max-h-\[400px\] {
  max-height: 400px;
}

.min-h-\[80px\] {
  min-height: 80px;
}

.min-h-\[10rem\] {
  min-height: 10rem;
}

.w-6 {
  width: 1.5rem;
}

.w-full {
  width: 100%;
}

.w-12 {
  width: 3rem;
}

.w-4 {
  width: 1rem;
}

.w-10 {
  width: 2.5rem;
}

.w-80 {
  width: 20rem;
}

.w-40 {
  width: 10rem;
}

.w-fit {
  width: -webkit-fit-content;
  width: fit-content;
}

.w-max {
  width: -webkit-max-content;
  width: max-content;
}

.min-w-\[240px\] {
  min-width: 240px;
}

.max-w-\[8\.5rem\] {
  max-width: 8.5rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-\[400px\] {
  max-width: 400px;
}

.max-w-none {
  max-width: none;
}

.flex-1 {
  flex: 1;
}

.flex-none {
  flex: none;
}

.flex-auto {
  flex: auto;
}

.flex-shrink, .shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@-webkit-keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  -webkit-animation: 1s linear infinite spin;
  animation: 1s linear infinite spin;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.appearance-none {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-items-start {
  align-items: start;
  justify-items: start;
}

.content-center {
  align-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: .25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-0 {
  gap: 0;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-0 {
  border-width: 0;
}

.border-b {
  border-bottom-width: 1px;
}

.border-none {
  border-style: none;
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: #e9eaee;
  border-color: rgba(233, 234, 238, var(--tw-border-opacity));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: #d3d4dc;
  border-color: rgba(211, 212, 220, var(--tw-border-opacity));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: #32bb78;
  border-color: rgba(50, 187, 120, var(--tw-border-opacity));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: #eb4755;
  border-color: rgba(235, 71, 85, var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: #bfc0cb;
  border-color: rgba(191, 192, 203, var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: #f4f4f6;
  border-color: rgba(244, 244, 246, var(--tw-border-opacity));
}

.border-\[\#061A37\]\/\[0\.08\] {
  border-color: rgba(6, 26, 55, .08);
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: #f4f4f6;
  background-color: rgba(244, 244, 246, var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: #32bb78;
  background-color: rgba(50, 187, 120, var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: #c5f7d9;
  background-color: rgba(197, 247, 217, var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-\[\#F9FAFB\] {
  --tw-bg-opacity: 1;
  background-color: #f9fafb;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.bg-\[\#EAE0C8\] {
  --tw-bg-opacity: 1;
  background-color: #eae0c8;
  background-color: rgba(234, 224, 200, var(--tw-bg-opacity));
}

.bg-\[\#F5F5DC\] {
  --tw-bg-opacity: 1;
  background-color: beige;
  background-color: rgba(245, 245, 220, var(--tw-bg-opacity));
}

.bg-\[\#0D120F\] {
  --tw-bg-opacity: 1;
  background-color: #0d120f;
  background-color: rgba(13, 18, 15, var(--tw-bg-opacity));
}

.bg-\[\#D3D4DC\] {
  --tw-bg-opacity: 1;
  background-color: #d3d4dc;
  background-color: rgba(211, 212, 220, var(--tw-bg-opacity));
}

.bg-\[\#A9ABB9\] {
  --tw-bg-opacity: 1;
  background-color: #a9abb9;
  background-color: rgba(169, 171, 185, var(--tw-bg-opacity));
}

.bg-\[\#D8E0E6\] {
  --tw-bg-opacity: 1;
  background-color: #d8e0e6;
  background-color: rgba(216, 224, 230, var(--tw-bg-opacity));
}

.bg-\[\#147AF3\] {
  --tw-bg-opacity: 1;
  background-color: #147af3;
  background-color: rgba(20, 122, 243, var(--tw-bg-opacity));
}

.bg-\[\#1446A0\] {
  --tw-bg-opacity: 1;
  background-color: #1446a0;
  background-color: rgba(20, 70, 160, var(--tw-bg-opacity));
}

.bg-\[\#51D6FF\] {
  --tw-bg-opacity: 1;
  background-color: #51d6ff;
  background-color: rgba(81, 214, 255, var(--tw-bg-opacity));
}

.bg-\[\#A4CDF4\] {
  --tw-bg-opacity: 1;
  background-color: #a4cdf4;
  background-color: rgba(164, 205, 244, var(--tw-bg-opacity));
}

.bg-\[\#E32636\] {
  --tw-bg-opacity: 1;
  background-color: #e32636;
  background-color: rgba(227, 38, 54, var(--tw-bg-opacity));
}

.bg-\[\#B7245C\] {
  --tw-bg-opacity: 1;
  background-color: #b7245c;
  background-color: rgba(183, 36, 92, var(--tw-bg-opacity));
}

.bg-\[\#A5243D\] {
  --tw-bg-opacity: 1;
  background-color: #a5243d;
  background-color: rgba(165, 36, 61, var(--tw-bg-opacity));
}

.bg-\[\#32BB78\] {
  --tw-bg-opacity: 1;
  background-color: #32bb78;
  background-color: rgba(50, 187, 120, var(--tw-bg-opacity));
}

.bg-\[\#8963BA\] {
  --tw-bg-opacity: 1;
  background-color: #8963ba;
  background-color: rgba(137, 99, 186, var(--tw-bg-opacity));
}

.bg-\[\#A18276\] {
  --tw-bg-opacity: 1;
  background-color: #a18276;
  background-color: rgba(161, 130, 118, var(--tw-bg-opacity));
}

.bg-\[\#E8BC50\] {
  --tw-bg-opacity: 1;
  background-color: #e8bc50;
  background-color: rgba(232, 188, 80, var(--tw-bg-opacity));
}

.bg-\[\#A87900\] {
  --tw-bg-opacity: 1;
  background-color: #a87900;
  background-color: rgba(168, 121, 0, var(--tw-bg-opacity));
}

.bg-\[\#FF7799\] {
  --tw-bg-opacity: 1;
  background-color: #f79;
  background-color: rgba(255, 119, 153, var(--tw-bg-opacity));
}

.bg-\[\#FDD630\] {
  --tw-bg-opacity: 1;
  background-color: #fdd630;
  background-color: rgba(253, 214, 48, var(--tw-bg-opacity));
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: #fff8d6;
  background-color: rgba(255, 248, 214, var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: #2f313f;
  background-color: rgba(47, 49, 63, var(--tw-bg-opacity));
}

.bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: #f80;
  background-color: rgba(255, 136, 0, var(--tw-bg-opacity));
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: #a9abb9;
  background-color: rgba(169, 171, 185, var(--tw-bg-opacity));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: #747682;
  background-color: rgba(116, 118, 130, var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: #e0fff0;
  background-color: rgba(224, 255, 240, var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: #e9eaee;
  background-color: rgba(233, 234, 238, var(--tw-bg-opacity));
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.fill-current {
  fill: currentColor;
}

.object-contain {
  object-fit: contain;
}

.p-6 {
  padding: 1.5rem;
}

.p-4 {
  padding: 1rem;
}

.p-3 {
  padding: .75rem;
}

.p-2 {
  padding: .5rem;
}

.p-0 {
  padding: 0;
}

.p-2\.5 {
  padding: .625rem;
}

.p-1 {
  padding: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-1\.5 {
  padding-top: .375rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pb-\[260px\] {
  padding-bottom: 260px;
}

.pt-3 {
  padding-top: .75rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-md {
  letter-spacing: -.011em;
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-sm {
  letter-spacing: -.006em;
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-2xl {
  letter-spacing: -.019em;
  font-size: 1.5rem;
  line-height: 1.875rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-xl {
  letter-spacing: -.017em;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.font-bold {
  font-weight: 650;
}

.font-semibold {
  font-weight: 600;
}

.font-normal {
  font-weight: 450;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-4 {
  line-height: 1rem;
}

.tracking-widest {
  letter-spacing: 1.25rem;
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(50, 187, 120, var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(116, 118, 130, var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(191, 192, 203, var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(47, 49, 63, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(235, 71, 85, var(--tw-text-opacity));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgba(201, 29, 43, var(--tw-text-opacity));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgba(29, 150, 92, var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(88, 90, 104, var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(244, 244, 246, var(--tw-text-opacity));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgba(255, 136, 0, var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(211, 212, 220, var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.placeholder-gray-700::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(116, 118, 130, var(--tw-placeholder-opacity));
}

.placeholder-gray-700::-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(116, 118, 130, var(--tw-placeholder-opacity));
}

.placeholder-gray-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(116, 118, 130, var(--tw-placeholder-opacity));
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.blur {
  --tw-blur: blur(8px);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-frombottom {
  --tw-drop-shadow: drop-shadow(0 0 12px rgba(0, 0, 0, .18));
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-\[1500ms\] {
  transition-duration: 1.5s;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.ReactModal__Body--open {
  overflow: hidden;
}

.after\:text-red-700:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: rgba(201, 29, 43, var(--tw-text-opacity));
}

.after\:content-\[\'_\*\'\]:after {
  --tw-content: " *";
  content: var(--tw-content);
}

.hover\:bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: #a9abb9;
  background-color: rgba(169, 171, 185, var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: #f9fafb;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.hover\:text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}

.focus\:border:focus {
  border-width: 1px;
}

.focus\:border-green-500:focus {
  --tw-border-opacity: 1;
  border-color: #32bb78;
  border-color: rgba(50, 187, 120, var(--tw-border-opacity));
}

.focus\:border-red-500:focus {
  --tw-border-opacity: 1;
  border-color: #eb4755;
  border-color: rgba(235, 71, 85, var(--tw-border-opacity));
}

.focus\:bg-white:focus {
  --tw-bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:bg-gray-200:disabled {
  --tw-bg-opacity: 1;
  background-color: #f4f4f6;
  background-color: rgba(244, 244, 246, var(--tw-bg-opacity));
}

[dir="rtl"] .rtl\:float-right {
  float: right;
}

[dir="rtl"] .rtl\:mr-1 {
  margin-right: .25rem;
}

[dir="rtl"] .rtl\:mr-2 {
  margin-right: .5rem;
}

[dir="rtl"] .rtl\:mr-4 {
  margin-right: 1rem;
}

[dir="rtl"] .rtl\:ml-1 {
  margin-left: .25rem;
}

[dir="rtl"] .rtl\:ml-3 {
  margin-left: .75rem;
}

[dir="rtl"] .rtl\:ml-2 {
  margin-left: .5rem;
}

[dir="rtl"] .rtl\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1;
}

[dir="rtl"] .rtl\:text-right {
  text-align: right;
}

@media (min-width: 640px) {
  .tablet\:static {
    position: static;
  }

  .tablet\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .tablet\:mt-0 {
    margin-top: 0;
  }

  .tablet\:block {
    display: block;
  }

  .tablet\:inline {
    display: inline;
  }

  .tablet\:flex {
    display: flex;
  }

  .tablet\:hidden {
    display: none;
  }

  .tablet\:h-auto {
    height: auto;
  }

  .tablet\:h-\[425px\] {
    height: 425px;
  }

  .tablet\:min-h-\[10rem\] {
    min-height: 10rem;
  }

  .tablet\:w-auto {
    width: auto;
  }

  .tablet\:w-16 {
    width: 4rem;
  }

  .tablet\:w-\[312px\] {
    width: 312px;
  }

  .tablet\:min-w-\[20rem\] {
    min-width: 20rem;
  }

  .tablet\:max-w-5xl {
    max-width: 64rem;
  }

  .tablet\:flex-row {
    flex-direction: row;
  }

  .tablet\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .tablet\:flex-col {
    flex-direction: column;
  }

  .tablet\:justify-between {
    justify-content: space-between;
  }

  .tablet\:justify-around {
    justify-content: space-around;
  }

  .tablet\:self-end {
    align-self: flex-end;
  }

  .tablet\:rounded-none {
    border-radius: 0;
  }

  .tablet\:border {
    border-width: 1px;
  }

  .tablet\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: #e9eaee;
    border-color: rgba(233, 234, 238, var(--tw-border-opacity));
  }

  .tablet\:bg-transparent {
    background-color: rgba(0, 0, 0, 0);
  }

  .tablet\:p-6 {
    padding: 1.5rem;
  }

  .tablet\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .tablet\:text-left {
    text-align: left;
  }

  .tablet\:text-center {
    text-align: center;
  }

  [dir="rtl"] .tablet\:rtl\:text-center {
    text-align: center;
  }

  [dir="rtl"] .tablet\:rtl\:text-right {
    text-align: right;
  }
}

@media (min-width: 992px) {
  .desktop\:absolute {
    position: absolute;
  }

  .desktop\:left-1\/2 {
    left: 50%;
  }

  .desktop\:right-4 {
    right: 1rem;
  }

  .desktop\:top-2 {
    top: .5rem;
  }

  .desktop\:max-w-xl {
    max-width: 36rem;
  }

  .desktop\:flex-none {
    flex: none;
  }

  .desktop\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .desktop\:transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

/*# sourceMappingURL=index.bbada848.css.map */
